import React from "react"
import { useHeaderImage } from "./useHeaderImage"

import Image from "gatsby-image"

export const useEnHeaderData = () => {
  const images = useHeaderImage()

  return {
    mini_kampanie: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: "Futuro and Viscoplast – mini-campaigns",
    },
    shell: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: "Shell deli2go – Snack bars at petrol stations.",
    },
    cersanit: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: "Cersanit and Opoczno - a shop-in-shop project.",
    },
    futuro: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: <>Futuro i&nbsp;Viscoplast – mini kampanie.</>,
    },
    vox: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: "VOX – a modular display system",
    },
  }
}
