import {useCaseData} from '../../shared/Hooks/Case';
import {useEnHeaderData} from '../../shared/Hooks/Headers/useEnHeaderData';

export const useData = () => {
  const caseData = useCaseData().cersanit;
  const header = useEnHeaderData();

  return {
    seo: {
      title: 'Case study',
    },
    cases: caseData,
    header,
  };
};
